module services {
    export module master {
        export class cargoTypeService implements interfaces.master.ICargoTypeService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            GetList(): ng.resource.IResourceClass<interfaces.master.ICargoType>
            {
                return this.$resource<interfaces.master.ICargoType>(this.ENV.DSP_URL + "CargoType/GetList",
                    {
                    });
            }
        }
    }
    angular.module("app").service("cargoTypeService", services.master.cargoTypeService);
}